// import logo from './logo.svg';
import './App.css';
import MainBody from './components/common/MainBody'

function App() {
  return (
    <MainBody />
    // <div className="App">
    //   aa
    // </div>
  );
}

export default App;
