import React from 'react'
import Paper from '@material-ui/core/Paper';
import ExpNav from './MobExpNav'
import ExpTopLabel from './MobExpTopLabel'
// import ExpTimeline from './MobExpNavTimeline'
// import ExpContnt from '../../../not_used/MobExpContentAcco'
// import ExpnewCnt from './MobExpNewAccord'
import MobExptab from './MobExpTabbed'
// setMainshow={setMainBox} setexp={setShowexp}
function ExpPage(props){

    const handleBacknav=()=>{
        props.setmobMainshow(true)
        props.setMobShowExp(false)
      }

    return <div><ExpTopLabel bodytext='Experience' backBtn={handleBacknav} /><Paper elevation={0} className='mobexpmain' >
    {/* <ExpNav /> */}
    {/* <ExpTimeline /> */}
    {/* <ExpContnt /> */}
    {/* <ExpnewCnt /> */}
    <MobExptab />
    </Paper></div>
}

export default ExpPage