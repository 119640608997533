import React from 'react';
// import { SliderPicker } from 'react-color';
import Box from '@material-ui/core/Box';
import { useNeumorphShadowStyles } from '@mui-treasury/styles/shadow/neumorph';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';

import topLabelBtnback from '../../images/toplabelbtn.png'

const NeumorphShadow = (props) => {
    const [bgColor, setBgColor] = React.useState('#797a7e');
    const classes = useNeumorphShadowStyles({ bgColor });
    return (
      <>
      {/* <div style={{position:'relative',width:'100px',backgroundColor:'red',left:'100px'}}>a</div> */}
        <Box
          p={1}
          bgcolor={bgColor}
          width={'100%'}
          height={'10%'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          flex={1}
          className='mobtoplabelback'
        >
          {/* <Box classes={classes} borderRadius={16} width={240} height={90} justifyContent={'center'} alignItems={'center'} style={{position:'absolute',left:'10px'}}><ArrowBackIcon /></Box> */}
          <Box style={{position:'absolute',left:'10px'}}><IconButton aria-label="back">
          <ArrowBackIcon fontSize='large' onClick={()=>props.backBtn()} />
      </IconButton></Box>
          <Box classes={classes} style={{backgroundColor:'#686d76',backgroundImage: "url(" + topLabelBtnback + ")"}} borderRadius={16} width={240} height={'90'} justifyContent={'center'} alignItems={'center'}>
          <div style={{position:'relative',margin:'auto',left:'15%'}}><Typography variant="h3" gutterBottom className='mobaboutmetoplabel'>
        About Me
  </Typography></div>
          
          </Box>
        </Box>
        {/* <Box width={'100%'}>
          <SliderPicker
            color={bgColor}
            onChange={color => setBgColor(color.hex)}
          />
        </Box> */}
      </>
    );
  };
  
  export default NeumorphShadow;