import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import portprojback from '../../images/portprojback.png'
// import reactskill from '../../images/projicons/react_icon.png'

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    backgroundColor: '#7a7a7a',
    backgroundImage: `url("${portprojback}")`
  },
});
// background-color: #7a7a7a;
// background-image: url("https://www.transparenttextures.com/patterns/black-felt.png");
/* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
export default function ImgMediaCard(props) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea>
        {/* <CardMedia
          component="img"
          alt="Contemplative Reptile"
          height="140"
          image="/static/images/cards/contemplative-reptile.jpg"
          title="Contemplative Reptile"
        /> */}
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2" style={{color:'#222831',fontFamily:"'Caveat', cursive",fontWeight:'bold',fontSize:'30px'}}>
      {props.projname}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
           <br/>
           {/* <br/> */}
           {props.projicons.map((rw)=><img
            width="50" 
            height="50"
            style={{margin:'2px'}}
            alt={''}
            src={
              // props.skillicon
              rw
            }
          />)}
           
          {/* <img
            width="80" 
            height="80"
            alt={''}
            src={
              // props.skillicon
              'https://s2.qwant.com/thumbr/0x380/6/5/4c09d1fdffb7467b8547e4e8172ede3b098ec73789f890fec8f8a2c4b36e42/docker-icon_copy.png?u=https%3A%2F%2Fcdn4.iconfinder.com%2Fdata%2Ficons%2Fsocial-network-round-gloss-shine%2F512%2Fdocker-icon_copy.png&q=0&b=1&p=0&a=1'
            }
          /> */}
           {/* <br/>
           <br/>
           <br/>
           <br/> */}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
      <Link href={props.desc}   target="_blank"  rel="noopener" variant="body4" style={{color:'#a3d2ca',fontFamily:"'Caveat', cursive",fontWeight:'bold',fontSize:'20px'}}>  Description</Link>
      <Link href={props.codelink}   target="_blank"  rel="noopener" variant="body4" style={{color:'#a3d2ca',fontFamily:"'Caveat', cursive",fontWeight:'bold',fontSize:'20px'}} >  Code</Link>
        {/* <Button size="small" color="primary">
          Description
        </Button>
        <Button size="small" color="primary">
          Code
        </Button> */}
      </CardActions>
    </Card>
  );
}