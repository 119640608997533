import React from 'react'
import Grid from '@material-ui/core/Grid'


function MainGriditem(){
    return <Grid item xs={6}>
    {/* <Paper className={classes.paper}>item</Paper> */}
    new1
  </Grid>
}

export default MainGriditem