import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Maingriditem from './MainGridItems'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography';

import aboutmeicon from '../../images/aboutmeicon.svg';
import skills from '../../images/cert.svg';
import exp from '../../images/exp.svg';
import port from '../../images/port.svg';

const useStyles = makeStyles((theme) => ({
    midbox: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      height:100
    },
  }));
  


function MidMainbox(props){
    const classes = useStyles()

    function setDisplays(){
      props.setMainshow(false)
      props.setabout(true)
    }

    function setDisplaySkill(){
      props.setMainshow(false)
      props.setskill(true)
    }

    function setDisplayExp(){
      props.setMainshow(false)
      props.setShowExp(true)
    }

    function setDisplayPort(){
      props.setMainshow(false)
      props.setShowPort(true)
    }

    



    return     <Grid container xs={6} spacing={9} className='midbox'>
        {/* <Grid item xs={6}  style={{backgroundColor:'yellow'}}> */}
        <Grid item xs={6}  > 
          <img onClick={setDisplays} className='webmainabouticon'
        // className={classes.img}
          // src='https://www.flaticon.com/svg/static/icons/svg/2620/2620498.svg'
          src={aboutmeicon}
        // alt={tutorialSteps[activeStep].label}
        />
      <Typography component="h2" variant="h3" gutterBottom className='midboxlabels1'>
        About Me
      </Typography>
        {/* <Paper className={classes.paper} onClick={setDisplays} ><img className='webmainabouticon'
        // className={classes.img}
        src='https://www.flaticon.com/svg/static/icons/svg/2620/2620498.svg'
        // alt={tutorialSteps[activeStep].label}
      />About me/Education</Paper> */}
        </Grid>
        {/* <Grid  item xs={6}   style={{backgroundColor:'blue'}}> */}
        <Grid  item xs={6}   >
          <img onClick={setDisplaySkill} className='webmainabouticon'
          // className={classes.img}
          src={skills}
          // alt={tutorialSteps[activeStep].label}
        />
        <Typography component="h2" variant="h3" gutterBottom className='midboxlabels2'>
          Skills
        </Typography>
        {/* <Paper className={classes.paper} onClick={setDisplaySkill} >Skills</Paper> */}
        </Grid>
        {/* <Grid item xs={6}   style={{backgroundColor:'green'}}> */}
        <Grid item xs={6}   >
          <img onClick={setDisplayExp} className='webmainabouticon'
        // className={classes.img}
          src={exp}
        // alt={tutorialSteps[activeStep].label}
        />
        <Typography component="h2" variant="h3" gutterBottom className='midboxlabels3'>
          Experience
        </Typography>
        {/* <Paper className={classes.paper} onClick={setDisplayExp} >Experience</Paper> */}
        </Grid>
        {/* <Grid item xs={6}   style={{backgroundColor:'orange'}}> */}
        <Grid item xs={6}   >
          <img onClick={setDisplayPort} className='webmainabouticon'
          // className={classes.img}
          src={port}
        // alt={tutorialSteps[activeStep].label}
        />
        <Typography component="h2" variant="h3" gutterBottom className='midboxlabels4'>
          Portfolio
        </Typography>
        {/* <Paper className={classes.paper} onClick={setDisplayPort} >Portfolio</Paper> */}
        </Grid>
    </Grid>
}

export default MidMainbox




    // <Grid container spacing={1} xs={6} className='midbox'>
    //     <Grid container item xs={12} spacing={3} style={{backgroundColor:'yellow'}}>
    //       <Maingriditem />
    //       <Maingriditem />
    //     </Grid>
    //     <Grid container item xs={12} spacing={3} style={{backgroundColor:'blue'}}>
    //       <Maingriditem />
    //       <Maingriditem />
    //     </Grid>
    // </Grid>