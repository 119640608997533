import React,{useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import MainTitleGrid from './MobMainTitleSkillgrid'
import MainTitleIcon from './MobMainTitleIcons'
import Chip from '@material-ui/core/Chip';
import MobMaintitlelvl1icons from './MobMaintitleIconslv1'
import MobMaintitlescroll from './MobMainTitleSkillsscroll'
import Badge from '@material-ui/core/Badge';
import MailIcon from '@material-ui/icons/Mail';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import { useCookies } from 'react-cookie'
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
    position:"relative",
    top:'6%',
    left: '32%'
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    left: '200px'
  },
}));

export default function SimplePaper(props) {
  const classes = useStyles();
  // const [cntrval,setCntr]=useState(0)
  // const [cookies, setCookie] = useCookies(['countcookie'])

  // useEffect(()=>{
  //   async function getdata(){
  //     const apiurl=`${process.env.REACT_APP_API_HOST}/getcurrentcount`
  //   // const apidata={action:'update'}
  //   const resp=await fetch(apiurl,{
  //     method:'GET',
  //     // body:JSON.stringify(apidata),
  //     headers:{
  //       // 'Authorization':`Bearer ${props.passAuthTkn}`,
  //       'Content-Type':'application/json'
  //     }
  //   })
  //   const respjson=await resp.json()
  //   setCntr(respjson.countval)
  //   // console.log(cookies.countcookie)
  //   // console.log(respjson)
  //   }

  //   async function updatedata(){
  //     const apiurl=`${process.env.REACT_APP_API_HOST}/updatecount`
  //     const apidata={action:'update'}
  //     const resp=await fetch(apiurl,{
  //       method:'POST',
  //       body:JSON.stringify(apidata),
  //       headers:{
  //         // 'Authorization':`Bearer ${props.passAuthTkn}`,
  //         'Content-Type':'application/json'
  //       }
  //     })
  //   const respjson=await resp.json()
  //   setCookie('countcookie', 'yes', { path: '/' });
  //   setCntr(respjson.newcount)
  //   // console.log(cookies.countcookie)
  //   // console.log(respjson)
  //   }
  //   getdata()
  //   if(!cookies.countcookie){
  //     updatedata()
  //     // console.log('aa')
  //   }
    

  // },[])
  

  return (
      <div className='mobmaintitlediv' >
          {/* <Avatar alt="Remy Sharp" src="https://s2.qwant.com/thumbr/0x380/6/3/4bdcc3ed85a7fc3fab25c8d5e35ec92fff7ea488e81eb2aa1cd7e60a519751/img_avatar2.png?u=https%3A%2F%2Fwww.w3schools.com%2Fw3css%2Fimg_avatar2.png&q=0&b=1&p=0&a=1" className={classes.large} /> */}
          <Typography variant="h4" component="h2" className='mobmaintitlename' >Amlan Chakladar{!props.errShow && <Badge badgeContent={props.visCnt} max={10000000} color="primary" style={{position:'absolute',right:'35%',top:'12px'}}>
          <PeopleAltIcon fontSize="medium" />
          </Badge>}
          {props.errShow && <Badge color="primary" style={{position:'absolute',right:'35%',top:'12px'}}>
          {/* <SupervisorAccountIcon fontSize="large" /> */}
          <ErrorIcon fontSize="medium" />
        </Badge>}
          </Typography>
          <MobMaintitlelvl1icons />
          <MainTitleIcon />
          {/* <Badge badgeContent={10000} max={10000000} color="primary" style={{right:'2%',top:'12px'}}>
        <PeopleAltIcon fontSize="medium" />
      </Badge> */}
          <MobMaintitlescroll />
          {/* <MainTitleGrid /> */}
          {/* <Typography variant="h6" component="h2" className='mobmaintitlename2' >Oracle CX Cloud Architect | AWS Certified Architect & Developer | Cloud DevOps Architect | HarvardX Certified Python/JavaScript Developer | Azure/Microsoft Certified</Typography> */}
          {/* <Typography variant="h5" component="h2" className='webmaintitlename2' >Oracle CX Cloud Architect | AWS Certified Architect & Developer | Cloud DevOps Architect | HarvardX Certified Python/JavaScript Developer | Azure/Microsoft Certified</Typography>
          <MainTitleIcon />
          
          // <MainTitleGrid /> */}
          
      </div>
    // <div className={classes.root}>
    //   {/* <Paper elevation={0} />
    //   <Paper /> */}
    //   <Paper elevation={24} className='webmaintitlebox' />
    // </div>
  );
}